$separator-size: 1px;

.base {
  background-color: var(--color-borders-hairline);

  &[data-orientation="horizontal"] {
    height: $separator-size;
    width: 100%;
  }

  &[data-orientation="vertical"] {
    height: 100%;
    width: $separator-size;
  }
}
