@use "src/styles/breakpoints";

// dynamic global variables

:root {
  // TODO: migrate constants.scss to use css variables
  --navbar-height: 3.6rem;
  --navbar-container-height: var(--navbar-height);
  --banner-height: 3rem;
}

.banner-active {
  --banner-height: 3rem;
  --navbar-container-height: calc(var(--navbar-height) + var(--banner-height));
  @include breakpoints.tablet {
    --banner-height: var(--navbar-height);
    --navbar-container-height: var(--navbar-height);
  }
}
