$animationDuration: 0.35s;
$animationEasing: cubic-bezier(0.4, 0, 0.2, 1);

$min-width: calc(12 * var(--spacing-large));
$separator-size: 1px;

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.content {
  z-index: var(--z-index-sticky);
  padding-block-start: var(--spacing-small);
  padding-block-end: var(--spacing-small);
  padding-inline-start: 0;
  padding-inline-end: 0;
  min-width: $min-width;
  background-color: var(--color-background-elevated);
  border-radius: var(--border-radius-rounded);
  box-shadow: var(--shadow-large);
  animation-duration: $animationDuration;
  animation-timing-function: $animationEasing;
  will-change: transform, opacity;

  &[data-state="open"] {
    &[data-side="top"] {
      animation-name: slideDownAndFade;
    }
    &[data-side="right"] {
      animation-name: slideLeftAndFade;
    }
    &[data-side="bottom"] {
      animation-name: slideUpAndFade;
    }
    &[data-side="left"] {
      animation-name: slideRightAndFade;
    }
  }
}

.item {
  font-size: var(--font-size-normal);
  line-height: var(--line-height-normal);
  color: var(--color-text-default);
  display: flex;
  align-items: center;
  padding-block: var(--spacing-xsmall);
  padding-inline-start: var(--spacing-medium);
  padding-inline-end: var(--spacing-medium);
  position: relative;
  cursor: pointer;

  &[data-disabled] {
    color: var(--color-text-faded);
    pointer-events: none;

    & .iconWrapper {
      opacity: var(--opacity-50);
    }
  }

  &:focus {
    background-color: var(--color-background-alternative-medium);
    color: var(--color-text-default);
  }

  [dir="rtl"] & .iconWrapper.shouldFlipOnRTL {
    transform: scaleX(-1);
  }
  & .iconWrapper {
    margin-inline-end: var(--spacing-small);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--spacing-medium);
    width: var(--spacing-medium);
  }
}

.separator {
  height: $separator-size;
  background-color: var(--color-secondary-faded);
  margin-block-start: var(--spacing-xxsmall);
  margin-block-end: var(--spacing-xxsmall);
  margin-inline-start: var(--spacing-xxsmall);
  margin-inline-end: var(--spacing-xxsmall);
}

.selected {
  background-color: var(--color-background-alternative-medium);
}
