@use "src/styles/breakpoints";
@use "src/styles/constants";
@use "src/styles/utility";

.container {
  position: fixed;
  opacity: 1;
  width: 100%;
  inset-block-end: 0;
  text-align: center;
  background: var(--color-background-default);
  transition: var(--transition-regular);
  z-index: var(--z-index-sticky);
  box-shadow: var(--shadow-large);
  border-block-start: 1px solid var(--color-borders-hairline);
  height: constants.$audio-player-default-height;
  @include breakpoints.tablet {
    height: constants.$audio-player-default-desktop-height;
  }
  will-change: transform;
}

.containerHidden {
  display: none;
}

.spinner {
  align-items: center;
  display: flex;
  height: 100%;
}
